.button {
  border: none;
  padding: 0;
  margin-top: 10px;
  background-color: transparent;
}

.button:hover {
  opacity: 0.9;
}
