.header {
  /*background: transparent;*/
  /*background-image: linear-gradient(to right, #100 , #600);*/
  /*background-image: linear-gradient(to right, #300, #800);*/
  padding: 20px;
  box-shadow: inset 6px -6px 16px 0 rgba(0, 0, 0, 0.4), inset -6px 6px 16px 0 rgba(200, 200, 200, 0.25);
  position: relative;
  background-size: cover;
  /*background-attachment: fixed;*/
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 80px;
  background-image: linear-gradient(to right, rgba(65,10,0,0.9), rgba(45,50,90,0.3));
}

.title {
  color: #fff;
  font-weight: bold;
  font-size: 1.75em;
  line-height: 1.5em;
  font-family: Righteous;
  text-shadow: 0px 0px 8px #ff5f0d;
  position: relative;
}

.logo {
  height: 38px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 16px;
  margin-top: -12px;
  box-shadow: 0 0px 12px 0px rgba(255, 95, 13, 0.5);
}

@media(max-width: 576px) {
  .header{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .title{
    font-size: 1.5em;
    top: 5px;
  }

  .logo{
    margin-top: 0px;
    height: 32px;
  }
}
