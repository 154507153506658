.account {
  float: right;
  color: #fff;
}

.username{
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  margin-right: 15px;
}

.dropdownToggle{
  box-shadow: 2px -2px 8px 0 rgba(0, 0, 0, 0.5), -2px 2px 8px 0 rgba(255, 255, 255, 0.3);
}

@media(max-width: 576px) {
  .username{
    display: none;
  }
}